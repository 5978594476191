.round{
    border-radius:20px; /*supported by all latest Browser*/
   -moz-border-radius: 20px; /*For older Browser*/
   -webkit-border-radius: 20px;/*For older Browser*/
   height: 100%;
   width: 101%;
   overflow: hidden;
}

.desc li{
   list-style: none;
   font-size: 25px;
   margin-top: 30px;
}

.desc img{
   width: 900px;
}