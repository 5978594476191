.side-drawer{
    height: 100%;
    background: black;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.side-drawer li {
    margin: 0.5rem 0;
}

.side-drawer a {
    color: purple;
    text-decoration: none;
}

.side-drawer a:hover,
.side-drawer a:active {
    color: orange;
}

@media (min-width: 769px) {
    .side-drawer{
        display: none;
    }
}