.roundedDiv{
    border-radius:20px; /*supported by all latest Browser*/
    -moz-border-radius: 20px; /*For older Browser*/
    -webkit-border-radius: 20px;/*For older Browser*/
    height: auto;
    width: 70%;
}

.descriptione li{
   list-style: none;
   font-size: 25px;
   display: inline-block;
   margin: auto;
}


@media (max-width: 768px) {
    .descriptione li{
        font-size: 20px;
    }
}
