.registerDiv{
    border-radius:20px; /*supported by all latest Browser*/
    -moz-border-radius: 20px; /*For older Browser*/
    -webkit-border-radius: 20px;/*For older Browser*/
    height: auto;
    width: 70%;
}

.descri li{
   list-style: none;
   font-size: 25px;
   display: inline-block;
   margin: auto;
}

.objects p{
    display: inline-block;
    margin: auto;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .registerDiv{
        width: 100%;
    }
}

@media (max-height: 768px) {
    .registerDiv {
        overflow: scroll;
        overflow-x: hidden;
    }
}
