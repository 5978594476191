.homeChildren {
    overflow: hidden;
}

@media (max-height: 768px) {
    .homeChildren {
        overflow-y: scroll;
    }

    .childrenComp {
        overflow-y: scroll;
    }
}


.bluredDiv {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    }