@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #efefef;
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }

.site-header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
  height: 56px;
  background-color: black; }
  .site-header .brand-icon a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: white; }
  .site-header .brand-icon .icon {
    display: inline-flex;
    font-size: 2rem;
    height: 48px;
    width: 48px;
    justify-content: center;
    align-items: center;
    color: white; }
  .site-header .menu-trigger {
    display: inline-flex;
    flex-direction: column;
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: space-evenly;
    background-color: transparent;
    border: 0;
    outline: 0;
    position: relative;
    padding: 10px;
    color: white;
    cursor: pointer; }
    .site-header .menu-trigger span {
      display: inline-flex;
      width: 30px;
      height: 4px;
      border-radius: 16px;
      background-color: white;
      transition: all ease 0.5s; }
  .site-header .menu-close span {
    position: absolute;
    background-color: #efefef; }
    .site-header .menu-close span:nth-child(1) {
      transform: rotate(45deg); }
    .site-header .menu-close span:nth-child(3) {
      transform: rotate(-45deg); }
    .site-header .menu-close span:nth-child(2) {
      opacity: 0;
      height: 0;
      width: 0; }

.sidebarNavigationOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  background-color: transparent;
  z-index: 8; }

.menu-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  z-index: 9;
  width: 300px;
  right: 0;
  overflow: hidden; }

.menu-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #5f4bb6;
  z-index: -1; }

.sidebarNavigation {
  position: relative;
  width: 300px;
  height: 100%;
  background-color: #2e2e2e;
  padding-top: 50px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.75);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .sidebarNavigation::-webkit-scrollbar {
    width: 0px; }
  .sidebarNavigation .sidebar-top {
    overflow: hidden;
    display: flex;
    height: calc(100% - 260px); }
  .sidebarNavigation .links-wrapper {
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex-shrink: 0; }
    .sidebarNavigation .links-wrapper .menu-link {
      padding: 16px;
      display: flex;
      color: #f7f7f7;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;
      background-color: #2e2e2e;
      width: 280px; }
      .sidebarNavigation .links-wrapper .menu-link:hover {
        background-color: #151515; }

.extra-links {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none; }
  .extra-links .link-item {
    display: inline-flex;
    flex-direction: column;
    margin: 8px 16px;
    color: #a3a3a3; }
    .extra-links .link-item a {
      color: #a3a3a3; }
      .extra-links .link-item a:hover {
        color: #efefef; }
    .extra-links .link-item .link-title {
      font-weight: 700;
      color: #efefef;
      text-transform: uppercase;
      font-size: 0.85rem;
      letter-spacing: 2px;
      margin-bottom: 4px;
      color: #efefef; }
    .extra-links .link-item .social-media-links {
      display: flex;
      justify-content: flex-start; }
      .extra-links .link-item .social-media-links a {
        height: 30px;
        width: 30px;
        border: 0px solid;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        margin-right: 8px; }
        .extra-links .link-item .social-media-links a:hover {
          color: #212121;
          background-color: #efefef; }

.page {
  padding-top: 50px; }

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
  }
  
  .toggle-button:focus {
    outline: none;
  }
  
  .toggle-button__line {
    width: 30px;
    height: 2px;
    background: white;
  }
.toolbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #151719; 
    height: 56px;
}

.toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.toolbar__logo {
    margin-left: 1rem;
}

.toolbar__logo a{
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    width: 100%;
}

.spacer{
    flex: 1 1;
}

.toolbar_navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation-items li{
    padding: 0 0.5rem;
}

.toolbar_navigation-items a {
    color: white;
    text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:hover{
    color: orange;
}

@media (max-width: 768px) {
    .toolbar_navigation-items {
        display: none;
    }
}

@media (min-width: 769px) {
    .toolbar__toggle-button {
        display: none;
    }

    .toolbar__logo {
        margin-left: 0;
    }
}
.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
}
.side-drawer{
    height: 100%;
    background: black;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.side-drawer li {
    margin: 0.5rem 0;
}

.side-drawer a {
    color: purple;
    text-decoration: none;
}

.side-drawer a:hover,
.side-drawer a:active {
    color: orange;
}

@media (min-width: 769px) {
    .side-drawer{
        display: none;
    }
}
.homeChildren {
    overflow: hidden;
}

@media (max-height: 768px) {
    .homeChildren {
        overflow-y: scroll;
    }

    .childrenComp {
        overflow-y: scroll;
    }
}


.bluredDiv {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    }
.roundDiv{
     border-radius:20px; /*supported by all latest Browser*/
    -moz-border-radius: 20px; /*For older Browser*/
    -webkit-border-radius: 20px;/*For older Browser*/
    height: 100%;
    width: 101%;
    overflow: scroll;
    overflow-x: hidden;
}

.ContentDescription{
    margin-right: 20px;
}

.ContentDescription p{
    font-size: 20px;
    text-align: left;
}

.info {
    display: flex;
    flex-direction: column;
}

.description li{
    list-style: none;
    font-size: 25px;
    margin-top: 30px;
}
.description div{
    font-size: 25px;
    margin-top: 20px;
}

.description img{
    width: 500px;
    height: 300px;
    margin-right: 20px;
}

@media (max-width: 768px) {
    .description img {
        width: 100%;
    }
    .description li{
        font-size: 18px;
    }
}
.roundedDiv{
    border-radius:20px; /*supported by all latest Browser*/
    -moz-border-radius: 20px; /*For older Browser*/
    -webkit-border-radius: 20px;/*For older Browser*/
    height: auto;
    width: 70%;
}

.descriptione li{
   list-style: none;
   font-size: 25px;
   display: inline-block;
   margin: auto;
}


@media (max-width: 768px) {
    .descriptione li{
        font-size: 20px;
    }
}

.round{
    border-radius:20px; /*supported by all latest Browser*/
   -moz-border-radius: 20px; /*For older Browser*/
   -webkit-border-radius: 20px;/*For older Browser*/
   height: 100%;
   width: 101%;
   overflow: hidden;
}

.desc li{
   list-style: none;
   font-size: 25px;
   margin-top: 30px;
}

.desc img{
   width: 900px;
}
.registerDiv{
    border-radius:20px; /*supported by all latest Browser*/
    -moz-border-radius: 20px; /*For older Browser*/
    -webkit-border-radius: 20px;/*For older Browser*/
    height: auto;
    width: 70%;
}

.descri li{
   list-style: none;
   font-size: 25px;
   display: inline-block;
   margin: auto;
}

.objects p{
    display: inline-block;
    margin: auto;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .registerDiv{
        width: 100%;
    }
}

@media (max-height: 768px) {
    .registerDiv {
        overflow: scroll;
        overflow-x: hidden;
    }
}

.homeChildren {
    overflow: hidden;
}

@media (max-height: 768px) {
    .homeChildren {
        overflow-y: scroll;
    }

    .childrenComp {
        overflow-y: scroll;
    }
}


.bluredDiv {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    }
.infoCard {
    width: 200px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

