.roundDiv{
     border-radius:20px; /*supported by all latest Browser*/
    -moz-border-radius: 20px; /*For older Browser*/
    -webkit-border-radius: 20px;/*For older Browser*/
    height: 100%;
    width: 101%;
    overflow: scroll;
    overflow-x: hidden;
}

.ContentDescription{
    margin-right: 20px;
}

.ContentDescription p{
    font-size: 20px;
    text-align: left;
}

.info {
    display: flex;
    flex-direction: column;
}

.description li{
    list-style: none;
    font-size: 25px;
    margin-top: 30px;
}
.description div{
    font-size: 25px;
    margin-top: 20px;
}

.description img{
    width: 500px;
    height: 300px;
    margin-right: 20px;
}

@media (max-width: 768px) {
    .description img {
        width: 100%;
    }
    .description li{
        font-size: 18px;
    }
}